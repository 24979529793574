import _ from 'lodash'
import { POLICY_TYPE, isCustomPolicy } from 'mgr/events/utils/PolicyType'
import { genericTagToDisplayTag } from 'mgr/lib/components/GenericTagsDropDown'
import { getConfigProvider } from '@sevenrooms/connect-config'
import { srDelete, srGet, srPost, srPut, srUpload } from '@sevenrooms/core/api'
import { SET_FEATURE_FLAGS } from './ActionTypes'

export const requestEvent = (venueId, eventId) =>
  srGet(`/api-yoa/venue_events`, {
    venue: venueId,
    event_id: eventId,
  }).then(response => response.data.event)

export const requestSaveEvent = (event, state) => {
  if (event.id !== 'new') {
    return srPut(`/api-yoa/venue_events`, transformEventForApi(event, state)).then(response =>
      response.error ? response : response.data.event
    )
  }
  return srPost(`/api-yoa/venue_events`, transformEventForApi(event, state)).then(response =>
    response.error ? response : response.data.event
  )
}

export const requestExcludeDate = (event, state) =>
  srPut(`/api-yoa/venue_events/exclude`, transformEventForApi(event, state)).then(response =>
    response.error ? response : response.data.event
  )

export const requestSaveInventory = (currentEvent, inventory, upsells) => {
  if (inventory.id !== 'new') {
    return srPut(`/api-yoa/venue_inventory`, transformInventoryForApi(currentEvent, inventory, upsells)).then(response =>
      response.error ? response : response.data.inventory
    )
  }
  return srPost(`/api-yoa/venue_inventory`, transformInventoryForApi(currentEvent, inventory, upsells)).then(response =>
    response.error ? response : response.data.inventory
  )
}

export const requestDeleteInventory = inventory => {
  if (inventory.id !== 'new') {
    return srDelete(`/api-yoa/venue_inventory`, {
      venue: inventory.venue_id,
      id: inventory.id,
    })
  }
  // eslint-disable-next-line no-restricted-globals
  return Promise.resolve(event)
}

export const requestDeleteEvent = event => {
  if (event.id !== 'new') {
    return srDelete(`/api-yoa/venue_events`, {
      venue: event.venue_id,
      event_id: event.id,
    })
  }
  return Promise.resolve(event)
}

export const requestEvents = venueId =>
  srGet('/api-yoa/venue_events/list', { venue: venueId }).then(response => {
    const { events } = response.data
    const transformedEvents = events.map(event => ({
      ...transformEventFromApi(event),
      inventories: event.inventories.map(inventory => transformInventoryFromApi(inventory)),
    }))
    return {
      events: transformedEvents,
      upsells: response.data.upsells,
      default_booking_policy: response.data.default_booking_policy,
      default_cancellation_policy: response.data.default_cancellation_policy,
      min_price_enabled: response.data.min_price_enabled,
      tag_groups: response.data.tag_groups,
      booking_policies: response.data.booking_policies,
      cancellation_policies: response.data.cancellation_policies,
      venue_interactive_floorplan_images: response.data.venue_interactive_floorplan_images,
    }
  })

export const requestEventSales = (venueId, eventIds) =>
  srGet('/api-yoa/venue_events/sales', { venue: venueId, event_ids: eventIds }, { skipLoader: true }).then(
    response => response.data.event_sales
  )

export const requestEventInventories = (venueId, eventIds) =>
  srGet('/api-yoa/venue_events/inventories', {
    venue: venueId,
    event_ids: eventIds,
  }).then(response => {
    const eventInventories = response.data.event_inventories
    const transformedInventories = {}
    _.map(eventInventories, (inventories, eventId) => {
      transformedInventories[eventId] = inventories.map(inventory => transformInventoryFromApi(inventory))
    })
    return transformedInventories
  })

export const requestSeatingAreas = venueId => srGet(`/api-yoa/seating_areas`, { venue: venueId }).then(response => response.data)

export const uploadPhotoRequest = file =>
  srPost(`/upload-url`, { rurl: Math.random().toString() }, { convertToGetParams: true }).then(response =>
    response.error ? response : srUpload(response.upload_url, file)
  )

let transformEventForApi = (event, state) => {
  const bookingPolicies = state.venueConfig.booking_policies
  const cancellationPolicies = state.venueConfig.cancellation_policies

  let customBookingPolicy
  let bookingPolicyId
  if (isCustomPolicy(event.selected_booking_policy)) {
    customBookingPolicy = event.custom_booking_policy
  } else if (bookingPolicies) {
    bookingPolicyId =
      event.selected_booking_policy && event.selected_booking_policy !== POLICY_TYPE.DEFAULT
        ? event.selected_booking_policy
        : bookingPolicies[0].id
  }

  let customCancellationPolicy
  let cancellationPolicyId
  if (isCustomPolicy(event.selected_cancellation_policy)) {
    customCancellationPolicy = event.custom_cancellation_policy
  } else if (cancellationPolicies) {
    cancellationPolicyId =
      event.selected_cancellation_policy && event.selected_cancellation_policy !== POLICY_TYPE.DEFAULT
        ? event.selected_cancellation_policy
        : cancellationPolicies[0].id
  }

  const inventoryKeys = event.inventories.map(x => x.id).sort((ev1, ev2) => ev1.view_order - ev2.view_order)
  return {
    event_id: event.id,
    days: event.day_of_week.join(','),
    venue: event.venue_id,
    recurring_type: 'ONCE',
    ...event,
    photo_map: JSON.stringify(event.photo_map),
    inventory_keys: inventoryKeys,
    sales_data: {},
    inventories: [],
    custom_booking_policy: customBookingPolicy,
    custom_cancellation_policy: customCancellationPolicy,
    booking_policy_id: bookingPolicyId,
    cancellation_policy_id: cancellationPolicyId,
  }
}

let transformInventoryForApi = (currentEvent, inventory, upsells) => {
  const venueId = currentEvent.venue_id

  const selectedUpsells = _.reduce(
    upsells,
    (result, upsellValue, upsellKey) => (upsellValue.isSelected ? result.concat(upsellKey) : result),
    []
  )

  let customBookingPolicy
  let bookingPolicyId
  if (isCustomPolicy(inventory.selected_booking_policy)) {
    customBookingPolicy = inventory.custom_booking_policy
  } else if (inventory.selected_booking_policy !== POLICY_TYPE.EVENT) {
    bookingPolicyId = inventory.selected_booking_policy
  }

  let customCancellationPolicy
  let cancellationPolicyId
  if (isCustomPolicy(inventory.selected_cancellation_policy)) {
    customCancellationPolicy = inventory.custom_cancellation_policy
  } else if (inventory.selected_cancellation_policy !== POLICY_TYPE.EVENT) {
    cancellationPolicyId = inventory.selected_cancellation_policy
  }

  const transformed = {
    venue: venueId,
    ...inventory,
    seating_area: inventory.seating_area || '',
    admin_fee_type: inventory.handle_fee ? 'VENUE' : 'CONSUMER',
    guest_checkout: !inventory.guest_checkout,
    enable_promocodes: !inventory.enable_promocodes,
    upsell_categories: selectedUpsells,
    custom_booking_policy: customBookingPolicy,
    custom_cancellation_policy: customCancellationPolicy,
    booking_policy_id: bookingPolicyId,
    cancellation_policy_id: cancellationPolicyId,
    reservation_tag_full_hash: inventory.selected_tags.map(t => t.tagHash),
  }

  delete transformed.selected_tags

  return transformed
}

let transformInventoryFromApi = inventory => ({
  ...inventory,
  ...(inventory.start_time
    ? {
        sale_start_num: inventory.start_time.num,
        sale_start_hour:
          (inventory.start_time.absolute_hour ? moment(inventory.start_time.absolute_hour, 'hh:mm:ss').format('h:mm A') : '') ||
          inventory.start_time.reference_hour ||
          '',
        sale_start_type: inventory.start_time.type,
      }
    : {}),
  ...(inventory.end_time
    ? {
        sale_end_num: inventory.end_time.num,
        sale_end_hour:
          (inventory.end_time.absolute_hour ? moment(inventory.end_time.absolute_hour, 'hh:mm:ss').format('h:mm A') : '') ||
          inventory.end_time.reference_hour ||
          '',
        sale_end_type: inventory.end_time.type,
      }
    : {}),
  gratuity_amount: inventory.gratuity_amount || 0,
  charge_additional_fee: !!inventory.charge_additional_fee,
  charge_additional_fee_tax: !!inventory.charge_additional_fee_tax,
  handle_fee: inventory.admin_fee_type === 'VENUE',
  guest_checkout: !inventory.guest_checkout,
  enable_promocodes: !inventory.enable_promocodes,
  additional_fee_label: (inventory.additional_fee || {}).fee_label || '',
  additional_fee_amount: (inventory.additional_fee || {}).fee_amount || '',
  additional_fee_amount_type: (inventory.additional_fee || {}).fee_amount_type || 'PER_ORDER',
  additional_fee_tax_id: inventory.additional_fee_tax_id,
  booking_policy_id: inventory.booking_policy_id,
  cancellation_policy_id: inventory.cancellation_policy_id,
  selected_tags: (inventory.tags_display || []).map(genericTagToDisplayTag),
})

let transformEventFromApi = event => ({
  ...event,
  start_time: event.event_start_time.replace(/^[0]+/g, ''),
  end_time: event.event_end_time.replace(/^[0]+/g, ''),
})

export const getFeatureFlagsProvider = () =>
  getConfigProvider({
    prefabApiKey: window.globalInit.prefab_key,
    contexts: {
      venueUrlKey: [window.globalInit.venueUrlKey],
    },
  })

export const loadFeatureFlags = provider => {
  const useAdminPanelFloorplanImages = provider.isEnabled('events.use_admin_panel_floorplan_images')
  const isMultiInventoryTypeEnabled = provider.isEnabled('events.multi_inventory_type')
  return { type: SET_FEATURE_FLAGS, data: { useAdminPanelFloorplanImages, isMultiInventoryTypeEnabled } }
}
