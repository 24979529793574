import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { isCustomPolicy, POLICY_TYPE } from 'mgr/events/utils/PolicyType'
import { ExperienceType } from 'mgr/pages/shared/utils/Constants'
import { CheckBox, Input, Select, TextArea } from '../../../../common/FormElement'
import Switch from '../../../../common/Switch'
import * as styles from '../assets/styles/eventEditor'
import * as photoStyles from '../assets/styles/photos'
import { pad } from '../assets/styles/general'
import Cropper from '../containers/Cropper'
import InventoryList from '../containers/InventoryList'
import Photos from '../containers/Photos'
import Recurrence from '../containers/Recurrence'
import ControlBar from './ControlBar'
import DateTime from './DateTime'
import Header from './Header'
import RichText from './RichText'
import StartDateConfirmationBox from './StartDateConfirmationBox'
import { connect } from 'react-redux'

export const getMultiLanguagePolicyOrDefault = (policyTextMap, policyOptions, selectedPolicy) => {
  if (policyTextMap) {
    return policyTextMap[selectedPolicy] ? selectedPolicy : _.keys(policyOptions)[0]
  }
  return POLICY_TYPE.DEFAULT
}

const EditEvent = ({
  eventId,
  eventListData,
  loadEvent,
  saveEvent,
  venueId,
  updateEvent,
  updateDate,
  timeOptions,
  updateDescription,
  toggleRecurrence,
  toggleHidden,
  togglePrivate,
  toggle3dFloorplan,
  toggleMultiInventory,
  changeBookingPolicyType,
  changeBookingPolicy,
  changeCancellationPolicyType,
  changeCancellationPolicy,
  ux,
  updateLinkedExperience,
  experiences = [],
  cancelEdit,
  bookingPolicyOptions,
  cancellationPolicyOptions,
  bookingPolicyTextMap,
  cancellationPolicyTextMap,
  venueInteractiveFloorplanImages,
  featureFlags,
}) => {
  const head = (
    <wrap>
      <span onClick={loadEvent} style={{ cursor: 'pointer' }}>
        Events
      </span>{' '}
      / Event name
    </wrap>
  )
  const uxdata = ux
  const data = eventListData.find(item => item.id === eventId)
  const { invalidExperienceSelected } = uxdata
  const isEdit = !_.isNil(eventId) && eventId !== 'new'
  const hasPurchasedInventory = data?.sales_data?.tickets_sold > 0
  const [confirmStartDateSave, setConfirmStartDateSave] = useState(false)
  const saveEventAction =
    uxdata.editingEventStartDate && !data.is_recurring && isEdit && hasPurchasedInventory ? () => setConfirmStartDateSave(true) : saveEvent
  const saveCallback = uxdata.editingEvent ? saveEventAction : cancelEdit
  const experienceListOptions = experiences
    .sort((e1, e2) => {
      if (e1.status > e2.status) {
        return 1
      }
      if (e1.status < e2.status) {
        return -1
      }
      return 0
    })
    .reduce(
      (acc, experience) => {
        if (experience.offer_type === ExperienceType.EVENT) {
          // eslint-disable-next-line no-param-reassign
          acc[experience.id] = `${experience.name} (${experience.status})`
        }
        return acc
      },
      { None: '--select--' }
    )

  const [selectedBookingPolicy, setSelectedBookingPolicy] = useState()
  const [selectedCancellationPolicy, setSelectedCancellationPolicy] = useState()
  const [selectedBookingPolicyText, setSelectedBookingPolicyText] = useState()
  const [selectedCancellationPolicyText, setSelectedCancellationPolicyText] = useState()

  useEffect(() => {
    if (!data) {
      return
    }

    if (isCustomPolicy(data.selected_booking_policy)) {
      setSelectedBookingPolicy(POLICY_TYPE.CUSTOM)
      setSelectedBookingPolicyText(data.custom_booking_policy)
    } else {
      const selectedPolicy = getMultiLanguagePolicyOrDefault(bookingPolicyTextMap, bookingPolicyOptions, data.selected_booking_policy)
      setSelectedBookingPolicy(selectedPolicy)
      setSelectedBookingPolicyText(bookingPolicyTextMap[selectedPolicy])
    }

    if (isCustomPolicy(data.selected_cancellation_policy)) {
      setSelectedCancellationPolicy(POLICY_TYPE.CUSTOM)
      setSelectedCancellationPolicyText(data.custom_cancellation_policy)
    } else {
      const selectedPolicy = getMultiLanguagePolicyOrDefault(
        cancellationPolicyTextMap,
        cancellationPolicyOptions,
        data.selected_cancellation_policy
      )
      setSelectedCancellationPolicy(selectedPolicy)
      setSelectedCancellationPolicyText(cancellationPolicyTextMap[selectedPolicy])
    }
  }, [
    data?.selected_booking_policy,
    data?.custom_booking_policy,
    data?.selected_cancellation_policy,
    data?.custom_cancellation_policy,
    data,
    bookingPolicyTextMap,
    bookingPolicyOptions,
    cancellationPolicyTextMap,
    cancellationPolicyOptions,
  ])

  if (!data) {
    return null
  }

  return (
    <div style={{ ...styles.floatClear }}>
      <Header title={head} />
      <div id="event-editor" style={pad}>
        <form className="styled" id="event-form" data-sr-validate="always">
          <div style={{ ...styles.floatClear, marginTop: 10 }}>
            <Input
              labelText="Event name"
              name="name"
              value={data.name}
              required
              validator_type="not_empty"
              validator_message="Please name this event"
              onChangeHandler={updateEvent}
              inputCss={styles.eventName}
            />
          </div>
          <div>
            <CheckBox
              checkboxContainerId="event_is_recurring"
              testId="sr-checkbox-event_is_recurring"
              postLabelStyle={styles.linkCss}
              labelText="This event is recurring"
              name="is_recurring"
              onClickHandler={toggleRecurrence}
              checked={data.is_recurring}
            />
          </div>
          {data.is_recurring ? (
            <Recurrence eventId={eventId} timeOptions={timeOptions} />
          ) : (
            <div style={styles.dateWrapper}>
              <DateTime
                name="start"
                title="Starts"
                onChangeDate={updateDate}
                timeOptions={timeOptions}
                onChangeTime={updateEvent}
                data={data}
              />
              <DateTime
                name="end"
                title="Ends"
                onChangeDate={updateDate}
                timeOptions={timeOptions}
                onChangeTime={updateEvent}
                data={data}
              />
            </div>
          )}

          <Photos eventId={eventId} />
          {globalInit.venueSettings.enable_3d_floorplan && (
            <div style={{ marginTop: 10, ...styles.floatClear }}>
              <p className="group-label" style={{ margin: '10px 0 3px' }}>
                3D floorplan
              </p>
              <Switch
                name="use3dFloorplan"
                testId="sr-switch-3d-floorplan"
                toggle={toggle3dFloorplan}
                value={!data.enable_3d_floorplan}
                style={{ float: 'left' }}
              />
              <span style={styles.switchLabel}>Use 3D floorplan</span>
              {data.enable_3d_floorplan && featureFlags.useAdminPanelFloorplanImages && (
                <div style={styles.floatClear}>
                  <p className="group-label" style={{ margin: '10px 0 3px' }}>
                    Interactive Image *
                  </p>
                  <Select
                    name="inventory_floorplan_image_id"
                    validator_type="not_null"
                    validator_message="Attach an interactive image to enable the 3D floorplan."
                    options={venueInteractiveFloorplanImages.reduce(
                      (acc, image) => ({
                        ...acc,
                        [image.id]: image.name,
                      }),
                      { null: '--select--' }
                    )}
                    preInputStyle={{ minWidth: 300, width: 'auto' }}
                    inputCss={{ minWidth: 300, padding: '6px 20px 6px 6px', width: 'auto' }}
                    value={data.inventory_floorplan_image_id}
                    onChangeHandler={updateEvent}
                    MEDIA_URL={Pmp.Settings.MEDIA_URL}
                  />
                  <p style={{ ...styles.floatClear, color: '#999', fontStyle: 'italic', marginBottom: '10px' }}>
                    Select an interactive image to enable the 3D floorplan
                  </p>
                  {!data.inventory_floorplan_image_id || data.inventory_floorplan_image_id === 'null' ? (
                    <div style={{ ...styles.floatClear, ...photoStyles.bigPhoto, ...photoStyles.emptyPhoto, cursor: '' }} />
                  ) : (
                    <div style={styles.floatClear}>
                      <img
                        src={`/.h/download/${
                          venueInteractiveFloorplanImages.find(image => image.id === data.inventory_floorplan_image_id)?.raw_url
                        }`}
                        style={photoStyles.interactiveFloorplanPhoto}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div style={styles.rtWrapper}>
            <p className="group-label">Description</p>
            <RichText testId="sr-input-description" onChange={updateDescription} content={data.description} />
          </div>
          <div
            style={{
              ...styles.floatClear,
              width: '723px',
              padding: '0 0 0 0',
            }}
          >
            <p className="group-label">Booking Policy</p>
            <Select
              onChangeHandler={changeBookingPolicyType}
              value={selectedBookingPolicy}
              name="policy_type"
              options={bookingPolicyOptions}
              style={{ ...styles.floatClear }}
              MEDIA_URL={Pmp.Settings.MEDIA_URL}
              preInputStyle={{ width: 300 }}
              inputCss={{ width: 300 }}
            />
            <TextArea
              onChangeHandler={changeBookingPolicy}
              value={selectedBookingPolicyText}
              disabled={!isCustomPolicy(selectedBookingPolicy)}
              name="custom_booking_policy_textbox"
              style={{ ...styles.floatClear, marginTop: 3 }}
              inputCss={styles.customPolicies}
              required
              validator_type="not_empty"
              validator_message="Please enter text or use a default policy"
            />
          </div>
          <div
            style={{
              ...styles.floatClear,
              width: '723px',
              padding: '20px 0 30px 0',
            }}
          >
            <p className="group-label">Cancellation Policy</p>
            <Select
              onChangeHandler={changeCancellationPolicyType}
              value={selectedCancellationPolicy}
              name="cancellation_policy_type"
              options={cancellationPolicyOptions}
              style={{ ...styles.floatClear }}
              width={25}
              MEDIA_URL={Pmp.Settings.MEDIA_URL}
              preInputStyle={{ width: 300 }}
              inputCss={{ width: 300 }}
            />
            <TextArea
              onChangeHandler={changeCancellationPolicy}
              value={selectedCancellationPolicyText}
              disabled={!isCustomPolicy(selectedCancellationPolicy)}
              name="custom_cancellation_policy_textbox"
              style={{ ...styles.floatClear, marginTop: 3 }}
              inputCss={styles.customPolicies}
              required
              validator_type="not_empty"
              validator_message="Please enter text or use a default policy"
            />
          </div>
          <InventoryList eventId={eventId} venueId={venueId} />
          {featureFlags.isMultiInventoryTypeEnabled && globalInit.venueSettings.enable_multi_inventory && (
            <div style={{ marginTop: 30, ...styles.floatClear }}>
              <p className="group-label" style={{ margin: '10px 0 12px' }}>
                Combined Inventory Selection
              </p>
              <Switch
                name="is_multi_inventory"
                testId="sr-switch-multi-inventory"
                toggle={toggleMultiInventory}
                value={!data.is_multi_inventory}
                style={{ float: 'left' }}
              />
              <span style={styles.switchLabel}>Allow guests to select and checkout with more than one Inventory Type selected</span>
            </div>
          )}
          <div style={{ marginTop: 30, ...styles.floatClear }}>
            <p className="group-label" style={{ margin: '10px 0 12px' }}>
              Publish Event
            </p>
            <Switch
              name="hide_event"
              testId="sr-switch-publish_event"
              toggle={toggleHidden}
              value={data.hide_event}
              style={{ float: 'left' }}
            />
            <span style={styles.switchLabel}>Publish Event to Widget</span>
          </div>
          <div style={{ marginTop: 60, ...styles.floatClear }}>
            <p className="group-label">View access</p>
            <CheckBox
              name="private_event"
              labelText="Public"
              onClickHandler={togglePrivate}
              parentClass="radio-style"
              testId="sr-checkbox-event_public"
              checked={!data.private_event}
            />
            <CheckBox
              name="private_event"
              labelText="Private via specific URL"
              onClickHandler={togglePrivate}
              testId="sr-checkbox-event_private"
              parentClass="radio-style"
              checked={data.private_event}
            />
          </div>
          {Object.keys(experienceListOptions).length > 1 && (
            <div style={{ ...styles.floatClear, marginTop: 10 }}>
              <p className="group-label">Link to Offer</p>
              <Select
                name="experience_id"
                value={data.experience_id}
                options={experienceListOptions}
                preInputStyle={{ width: 200 }}
                onChangeHandler={e => updateLinkedExperience(e.target.value)}
                MEDIA_URL={Pmp.Settings.MEDIA_URL}
                style={{
                  border: invalidExperienceSelected ? '1px solid red' : 'none',
                }}
              />
              {invalidExperienceSelected && (
                <p style={{ display: 'block' }} className="validator">
                  Please select an offer that is compatible with your selected View access setting
                </p>
              )}
            </div>
          )}
        </form>
      </div>
      <ControlBar cancelAction={cancelEdit} saveAction={saveCallback} saveLock={uxdata.saveLock} />
      {uxdata.photoId ? <Cropper ux={uxdata} eventId={eventId} cropping={uxdata.cropping} /> : null}
      {confirmStartDateSave ? (
        <StartDateConfirmationBox cancelAction={() => setConfirmStartDateSave(false)} saveAction={saveEvent} saveLock={uxdata.saveLock} />
      ) : null}
    </div>
  )
}
const mapStateToProps = state => ({
  venueInteractiveFloorplanImages: state.venueConfig.venue_interactive_floorplan_images,
  featureFlags: state.featureFlags,
})
export default connect(mapStateToProps)(EditEvent)
