import _ from 'lodash'
import priceFormatter from 'currency-formatter'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { POLICY_TYPE, isCustomPolicy } from 'mgr/events/utils/PolicyType'
import Theme from 'mgr/layout/Theme'
import GenericTagsDropDown from 'mgr/lib/components/GenericTagsDropDown'
import { Input, CheckBox, TextArea, Select } from '../../../../common/FormElement'
import { roundMoney } from '../../../../common/Money'
import Switch from '../../../../common/Switch'
import { switchLabel } from '../assets/styles/eventEditor'
import { shadow } from '../assets/styles/general'
import * as styles from '../assets/styles/inventoryEditor'
import { getTypeOptions } from '../utils/InventoryType'
import ControlBar from './ControlBar'
import Header from './Header'
import RelativeTime from './RelativeTime'
import { connect } from 'react-redux'
import { notify } from '@sevenrooms/core/ui-kit/layout'

priceFormatter.currencies[0].symbol = 'AED'
priceFormatter.currencies[0].symbolOnLeft = true

const getMultiLanguagePolicy = (policyTextMap, policyOptions, selectedPolicy) =>
  policyTextMap[selectedPolicy] ? selectedPolicy : _.keys(policyOptions)[0]

const getEventPolicy = (selectedPolicy, customPolicy, policyTextMap) =>
  isCustomPolicy(selectedPolicy) ? customPolicy : policyTextMap[selectedPolicy]

const EditInventory = ({
  debouncePriceUpdateHandler,
  eventId,
  inventoryId,
  eventListData,
  loadInventory,
  seatingAreas,
  saveInventory,
  checkHandler,
  tieringCheckHandler,
  taxCheckHandler,
  handleAdditionalFee,
  handleAdditionalFeeTax,
  handleRadioUpdate,
  timeOptions,
  toggleHidden,
  updateInventory,
  toggleGuestCheckout,
  toggleEnablePromocodes,
  onToggleTiering,
  changeInventoryBookingPolicy,
  changeInventoryBookingPolicyType,
  changeInventoryCancellationPolicy,
  changeInventoryCancellationPolicyType,
  bookingPolicyOptions,
  cancellationPolicyOptions,
  bookingPolicyTextMap,
  cancellationPolicyTextMap,
  minPriceEnabled,
  tagGroups,
  venueId,
  changeSelectedTags,
  venueInteractiveFloorplanImages,
  featureFlags,
}) => {
  // Yeah so this is getting out of hand. I'll fix it.

  const eventData = eventListData.toJS().filter(item => item.id === eventId)[0]

  const data = eventData.inventories.filter(inv => inv.id === inventoryId)[0]

  const [selectedBookingPolicy, setSelectedBookingPolicy] = useState()
  const [selectedCancellationPolicy, setSelectedCancellationPolicy] = useState()
  const [selectedBookingPolicyText, setSelectedBookingPolicyText] = useState()
  const [selectedCancellationPolicyText, setSelectedCancellationPolicyText] = useState()
  const [svgClasses, setSvgClasses] = useState([])

  useEffect(() => {
    const fetchSvgClasses = async () => {
      if (!featureFlags.useAdminPanelFloorplanImages) {
        setSvgClasses([])
        return
      }

      const image = venueInteractiveFloorplanImages.find(image => eventData.inventory_floorplan_image_id === image.id)

      if (!image) {
        setSvgClasses([])
        return
      }

      try {
        const response = await fetch(`${window.origin}/.h/download/${image.raw_url}`)
        if (!response.ok) {
          setSvgClasses([])
          return
        }

        const svgText = await response.text()

        // Parse the SVG content
        const parser = new DOMParser()
        const svgDocument = parser.parseFromString(svgText, 'image/svg+xml')

        // Select all <path> elements
        const paths = svgDocument.querySelectorAll('path')

        // Extract class names
        const classNames = Array.from(
          new Set(
            Array.from(paths)
              .map(path => path.getAttribute('class'))
              .filter(path => path)
          )
        ).sort((a, b) => a.localeCompare(b))

        setSvgClasses(classNames)
      } catch (error) {
        notify({
          content: 'Failed to fetch floorplan image',
          type: 'error',
        })
        setSvgClasses([])
      }
    }

    fetchSvgClasses()
  }, [eventData.inventory_floorplan_image_id, featureFlags.useAdminPanelFloorplanImages, venueInteractiveFloorplanImages])

  useEffect(() => {
    if (!data) {
      return
    }

    if (isCustomPolicy(data.selected_booking_policy)) {
      setSelectedBookingPolicy(POLICY_TYPE.CUSTOM)
      setSelectedBookingPolicyText(data.custom_booking_policy)
    } else if (!!bookingPolicyTextMap && data.selected_booking_policy !== POLICY_TYPE.EVENT) {
      const selectedPolicy = getMultiLanguagePolicy(bookingPolicyTextMap, bookingPolicyOptions, data.selected_booking_policy)
      setSelectedBookingPolicy(selectedPolicy)
      setSelectedBookingPolicyText(bookingPolicyTextMap[selectedPolicy])
    } else {
      setSelectedBookingPolicy(POLICY_TYPE.EVENT)
      const eventPolicy = getEventPolicy(eventData.selected_booking_policy, eventData.custom_booking_policy, bookingPolicyTextMap)
      setSelectedBookingPolicyText(eventPolicy)
    }

    if (isCustomPolicy(data.selected_cancellation_policy)) {
      setSelectedCancellationPolicy(POLICY_TYPE.CUSTOM)
      setSelectedCancellationPolicyText(data.custom_cancellation_policy)
    } else if (!!cancellationPolicyTextMap && data.selected_cancellation_policy !== POLICY_TYPE.EVENT) {
      const selectedPolicy = getMultiLanguagePolicy(cancellationPolicyTextMap, cancellationPolicyOptions, data.selected_cancellation_policy)
      setSelectedCancellationPolicy(selectedPolicy)
      setSelectedCancellationPolicyText(cancellationPolicyTextMap[selectedPolicy])
    } else {
      setSelectedCancellationPolicy(POLICY_TYPE.EVENT)
      const eventPolicy = getEventPolicy(
        eventData.selected_cancellation_policy,
        eventData.custom_cancellation_policy,
        cancellationPolicyTextMap
      )
      setSelectedCancellationPolicyText(eventPolicy)
    }
    // eslint-disable-next-line
  }, [data?.selected_booking_policy, data?.custom_booking_policy, data?.selected_cancellation_policy, data?.custom_cancellation_policy])

  if (!data) {
    return null
  }

  const { applicable_dates } = eventData
  const venue_today = document.getElementById('today_date_url').value
  const venue_today_moment = moment(venue_today)
  const can_edit_comp = document.getElementById('can_edit_comp').value
  const first_applicable_date = _.head(_.filter(applicable_dates, x => moment(x) >= venue_today_moment)) || eventData.start_date
  const reference_sale_start_date_moment = moment(first_applicable_date)
  const reference_sale_end_date_moment = moment(data.sale_end_hour === 'EVENT_START_TIME' ? first_applicable_date : eventData.end_date)
  const reference_sale_start_time =
    // eslint-disable-next-line no-nested-ternary
    data.sale_start_hour === 'EVENT_START_TIME'
      ? eventData.start_time
      : data.sale_start_hour === 'EVENT_END_TIME'
      ? eventData.end_time
      : data.sale_start_hour
  const reference_sale_end_time =
    // eslint-disable-next-line no-nested-ternary
    data.sale_end_hour === 'EVENT_START_TIME'
      ? eventData.start_time
      : data.sale_end_hour === 'EVENT_END_TIME'
      ? eventData.end_time
      : data.sale_end_hour
  const reference_sale_start_time_moment = moment(reference_sale_start_time, ['h:mm A'])
  const reference_sale_end_time_moment = moment(reference_sale_end_time, ['h:mm A'])

  reference_sale_start_date_moment.set({
    hour: reference_sale_start_time_moment.get('hour'),
    minute: reference_sale_start_time_moment.get('minute'),
    second: reference_sale_start_time_moment.get('second'),
  })

  reference_sale_end_date_moment.set({
    hour: reference_sale_end_time_moment.get('hour'),
    minute: reference_sale_end_time_moment.get('minute'),
    second: reference_sale_end_time_moment.get('second'),
  })

  const priceUpdateHandler = e => {
    e.persist()
    debouncePriceUpdateHandler(e)
  }

  const first_sale_start_time_moment = reference_sale_start_date_moment.subtract(data.sale_start_num, data.sale_start_type)
  const first_sale_end_time_moment = reference_sale_end_date_moment.subtract(data.sale_end_num, data.sale_end_type)

  const sale_times_descriptive_text = eventData.is_recurring ? 'Next event sale' : 'Sale'
  const typeOptions = getTypeOptions()
  const seatingAreasLookup = _.fromPairs(_.map(seatingAreas.toJS().seating_areas, x => [x.id, x.name]))
  // Add default option
  seatingAreasLookup[''] = ''
  const default_gratuity = Number(document.getElementById('default_gratuity').value)
  const default_service_charge = Number(document.getElementById('default_service_charge').value)
  let total = Number(data.price)
  let grat = 0
  let _tax = 0
  let _grat = 0
  let _additional_fee = 0
  let _additional_fee_tax = 0
  const taxGroups = JSON.parse(document.getElementById('tax_groups').value)
  const taxGroupsTaxRates = {}
  const taxGroupsOptions = {}
  _.map(taxGroups, taxGroup => {
    const taxGroupId = taxGroup.id
    taxGroupsTaxRates[taxGroupId] = taxGroup.tax_rate
    taxGroupsOptions[taxGroupId] = taxGroup.tax_name
  })
  const taxGroupSelected = data.tax_group_id ? data.tax_group_id : ''
  const tax = data.tax_group_id in taxGroupsTaxRates ? taxGroupsTaxRates[data.tax_group_id] : 0

  let service_charge = 0
  if (data.apply_service_charge) {
    if (data.service_charge_type === 'DEFAULT_SERVICE_CHARGE') {
      service_charge = default_service_charge
    } else if (data.service_charge_type === 'SPECIFIC_SERVICE_CHARGE') {
      service_charge = Number(data.service_charge_amount || 0)
    }
  }

  const _service_charge = roundMoney((total * service_charge) / 100)
  const can_charge_tax = data.charge_tax && !_.isEmpty(taxGroupsOptions) && data.tax_group_id in taxGroupsTaxRates
  if (can_charge_tax) {
    _tax = roundMoney(((total + _service_charge) * tax) / 100)
  }

  const currency_code = document.getElementById('currency_code').value
  const currency_symbol = document.getElementById('currency_symbol').value

  if (data.charge_gratuity) {
    if (data.gratuity_amount_type === 'DEFAULT_FIXED') {
      grat = default_gratuity
    } else if (data.gratuity_amount_type === 'CUSTOM_FIXED') {
      grat = Number(data.gratuity_amount || 0)
    }
    _grat = roundMoney((total * grat) / 100)
  }

  if (data.charge_additional_fee) {
    if (data.additional_fee_amount_type === 'PERCENT_OF_BASE') {
      _additional_fee = (total * data.additional_fee_amount) / 100
    } else {
      _additional_fee = data.additional_fee_amount / 1
    }
    _additional_fee = roundMoney(_additional_fee)
  }

  if (data.charge_additional_fee_tax) {
    const additional_fee_tax = data.additional_fee_tax_id in taxGroupsTaxRates ? taxGroupsTaxRates[data.additional_fee_tax_id] : 0
    _additional_fee_tax = roundMoney((_additional_fee * additional_fee_tax) / 100)
  }

  total += _grat + _service_charge + _tax + _additional_fee + _additional_fee_tax

  total = `${currency_symbol} ${total.toFixed(2)}`

  const trElement = (val1, val2, trStyle) => (
    <tr style={trStyle}>
      <td style={{ border: 0, width: 100, paddingLeft: 15 }}>{val1}</td>
      <td style={{ border: 0, width: 100, textAlign: 'right', paddingRight: 15 }}>{val2}</td>
    </tr>
  )

  const paymentControls = (
    <div style={styles.payments}>
      <table>
        <tbody>
          <tr>
            <td style={{ border: 0, verticalAlign: 'top' }}>
              <div style={styles.taxCol}>
                <p className="group-label">Additional charges</p>
                <CheckBox
                  checkboxLabelId="inventory-apply_service_charge"
                  labelText="Apply Service Charge (taxed)"
                  name="apply_service_charge"
                  checked={data.apply_service_charge}
                  onClickHandler={checkHandler}
                />
                {data.apply_service_charge ? (
                  <span>
                    <div style={{ clear: 'both', marginLeft: 10 }}>
                      <CheckBox
                        checkboxLabelId="inventory-default-service_charge"
                        id="DEFAULT_SERVICE_CHARGE"
                        labelText={`Default Service Charge (${default_service_charge}%)`}
                        name="service_charge_type"
                        parentClass="radio-style"
                        value="DEFAULT_SERVICE_CHARGE"
                        preInputStyle={{ float: 'left' }}
                        postLabelStyle={{ float: 'left' }}
                        disabled={data.service_charge_type === 'DEFAULT_SERVICE_CHARGE'}
                        checked={data.service_charge_type === 'DEFAULT_SERVICE_CHARGE'}
                        onClickHandler={handleRadioUpdate}
                      />
                    </div>

                    <div style={{ clear: 'both', marginLeft: 10 }}>
                      <CheckBox
                        checkboxLabelId="inventory-specific-service_charge"
                        id="SPECIFIC_SERVICE_CHARGE"
                        name="service_charge_type"
                        labelText="Specific Service Charge"
                        preInputStyle={{ float: 'left' }}
                        parentClass="radio-style"
                        postLabelStyle={{ float: 'left' }}
                        disabled={data.service_charge_type === 'SPECIFIC_SERVICE_CHARGE'}
                        checked={data.service_charge_type === 'SPECIFIC_SERVICE_CHARGE'}
                        value="SPECIFIC_SERVICE_CHARGE"
                        onClickHandler={handleRadioUpdate}
                      />
                      {data.service_charge_type === 'SPECIFIC_SERVICE_CHARGE' ? (
                        <div className="form-element">
                          <div
                            style={{
                              border: '1px solid #CCC',
                              float: 'left',
                              margin: '15px 0px 0px 10px',
                            }}
                          >
                            <input
                              defaultValue={data.service_charge_amount}
                              style={{
                                float: 'left',
                                width: 30,
                                fontFamily: 'Roboto, sans-serif',
                                border: 0,
                              }}
                              type="text"
                              name="service_charge_amount"
                              min={0.1}
                              onChange={updateInventory}
                              data-sr-validate="positive_float"
                              data-validator_message="Enter a specific service charge percentage"
                            />
                            <div
                              style={{
                                float: 'left',
                                lineHeight: '28px',
                                paddingRight: '5px',
                              }}
                            >
                              %
                            </div>
                          </div>
                          <p className="validator" data-sr-validate-error="Enter a specific service charge percentage">
                            Enter a specific charge percentage
                          </p>
                        </div>
                      ) : undefined}
                    </div>
                  </span>
                ) : null}
                <CheckBox
                  checkboxLabelId="inventory-charge_tax"
                  labelText="Apply tax"
                  name="charge_tax"
                  disabled={_.isEmpty(taxGroupsOptions)}
                  checked={can_charge_tax}
                  onClickHandler={e => taxCheckHandler(e, taxGroupsOptions)}
                />
                {can_charge_tax && (
                  <Select
                    name="tax_group_id"
                    value={taxGroupSelected}
                    options={taxGroupsOptions}
                    preInputStyle={{ width: 180 }}
                    style={$.extend({}, styles.rowItems, {
                      width: 180,
                      marginTop: 28,
                      paddingTop: 10,
                    })}
                    onChangeHandler={updateInventory}
                    MEDIA_URL={Pmp.Settings.MEDIA_URL}
                  />
                )}
                <CheckBox
                  checkboxLabelId="inventory-charge_gratuity"
                  labelText="Apply Gratuity (not taxed)"
                  name="charge_gratuity"
                  checked={data.charge_gratuity}
                  onClickHandler={checkHandler}
                />
                {data.charge_gratuity ? (
                  <span>
                    <div style={{ clear: 'both', marginLeft: 10 }}>
                      <CheckBox
                        checkboxLabelId="inventory-default_gratuity_amount_type"
                        id="DEFAULT_FIXED"
                        labelText={`Default Gratuity (${default_gratuity}%)`}
                        name="gratuity_amount_type"
                        parentClass="radio-style"
                        value="DEFAULT_FIXED"
                        preInputStyle={{ float: 'left' }}
                        postLabelStyle={{ float: 'left' }}
                        disabled={data.gratuity_amount_type === 'DEFAULT_FIXED'}
                        checked={data.gratuity_amount_type === 'DEFAULT_FIXED'}
                        onClickHandler={handleRadioUpdate}
                      />
                    </div>
                    <div style={{ clear: 'both', marginLeft: 10 }}>
                      <CheckBox
                        checkboxLabelId="inventory-custom_gratuity_amount_type"
                        id="CUSTOM_FIXED"
                        labelText="Specific Gratuity"
                        name="gratuity_amount_type"
                        parentClass="radio-style"
                        value="CUSTOM_FIXED"
                        preInputStyle={{ float: 'left' }}
                        postLabelStyle={{ float: 'left' }}
                        disabled={data.gratuity_amount_type === 'CUSTOM_FIXED'}
                        checked={data.gratuity_amount_type === 'CUSTOM_FIXED'}
                        onClickHandler={handleRadioUpdate}
                      />
                      {data.gratuity_amount_type === 'CUSTOM_FIXED' ? (
                        <div className="form-element">
                          <div
                            style={{
                              border: '1px solid #CCC',
                              float: 'left',
                              margin: '15px 0px 0px 10px',
                            }}
                          >
                            <input
                              defaultValue={data.gratuity_amount}
                              style={{
                                float: 'left',
                                width: 30,
                                fontFamily: 'Roboto, sans-serif',
                                border: 0,
                              }}
                              type="text"
                              name="gratuity_amount"
                              min={0.1}
                              onChange={updateInventory}
                              data-sr-validate="positive_float"
                              data-validator_message="Enter a specific gratuity percentage"
                            />
                            <div
                              style={{
                                float: 'left',
                                lineHeight: '28px',
                                paddingRight: '5px',
                              }}
                            >
                              %
                            </div>
                          </div>
                          <p
                            className="validator"
                            data-sr-validate-error="Enter a specific gratuity percentage"
                            style={{
                              margin: '10px 0px 0px 10px',
                            }}
                          >
                            Enter a specific charge percentage
                          </p>
                        </div>
                      ) : undefined}
                    </div>
                    <div style={{ clear: 'both', marginLeft: 10 }}>
                      <CheckBox
                        checkboxLabelId="inventory-customer_variable_gratuity_amount_type"
                        id="CUSTOMER_VARIABLE"
                        labelText="Allow Client to Select Gratuity"
                        name="gratuity_amount_type"
                        parentClass="radio-style"
                        value="CUSTOMER_VARIABLE"
                        preInputStyle={{ float: 'left' }}
                        postLabelStyle={{ float: 'left' }}
                        disabled={data.gratuity_amount_type === 'CUSTOMER_VARIABLE'}
                        checked={data.gratuity_amount_type === 'CUSTOMER_VARIABLE'}
                        onClickHandler={handleRadioUpdate}
                      />
                      {data.gratuity_amount_type === 'CUSTOMER_VARIABLE' ? (
                        <div style={{ clear: 'both', marginLeft: 10 }}>
                          <CheckBox
                            checkboxLabelId="inventory-require_gratuity_charge"
                            labelText="Require Client to Select"
                            name="require_gratuity_charge"
                            checked={data.require_gratuity_charge}
                            onClickHandler={checkHandler}
                          />
                        </div>
                      ) : null}
                    </div>
                  </span>
                ) : null}
              </div>
              <div style={styles.additionalPaymentCol}>
                <CheckBox
                  checkboxLabelId="inventory-charge_additional_fee"
                  labelText="Charge custom fee"
                  id="charge_additional_fee"
                  name="charge_additional_fee"
                  parentClass="checkbox-style"
                  checked={data.charge_additional_fee}
                  onClickHandler={handleAdditionalFee}
                />
                {data.charge_additional_fee ? (
                  <div style={{ clear: 'both', marginLeft: 25, paddingTop: 5 }}>
                    <span className="float-left">
                      <Input
                        style={{ float: 'right' }}
                        id="additional_fee_label"
                        name="additional_fee_label"
                        value={data.additional_fee_label}
                        onChangeHandler={updateInventory}
                        inputCss={{ width: 80 }}
                      />
                    </span>
                    <span className="float-left">
                      <Input
                        id="additional_fee_amount"
                        name="additional_fee_amount"
                        preLabelStyle={{
                          display: 'block',
                          margin: 0,
                          float: 'left',
                          lineHeight: '30px',
                        }}
                        labelText={data.additional_fee_amount_type === 'PERCENT_OF_BASE' ? '' : currency_symbol}
                        preInputStyle={{
                          border: 0,
                          margin: 0,
                          float: 'left',
                          lineHeight: '30px',
                          width: '75px',
                        }}
                        placeholder="0.00"
                        value={data.additional_fee_amount}
                        onChangeHandler={updateInventory}
                        inputCss={{ width: '100%', border: 0 }}
                        style={{
                          marginLeft: 10,
                          border: '1px solid  #CCC',
                          width: '100px',
                          borderRadius: '3px',
                        }}
                      />
                    </span>
                    <span className="float-left">
                      <Select
                        preInputStyle={{ width: 100 }}
                        options={{
                          PER_ORDER: 'per order',
                          PER_TICKET: 'per ticket',
                          PERCENT_OF_BASE: '% of base',
                        }}
                        inputCss={{ width: 100 }}
                        name="additional_fee_amount_type"
                        value={data.additional_fee_amount_type}
                        MEDIA_URL={Pmp.Settings.MEDIA_URL}
                        style={{ marginLeft: 10 }}
                        onChangeHandler={updateInventory}
                      />
                    </span>
                    <CheckBox
                      checkboxLabelId="inventory-charge_additional_fee_tax"
                      labelText="Apply custom fee tax"
                      id="charge_additional_fee_tax"
                      name="charge_additional_fee_tax"
                      parentClass="checkbox-style"
                      checked={data.charge_additional_fee_tax}
                      onClickHandler={e => handleAdditionalFeeTax(e, taxGroupsOptions)}
                    />
                    {data.charge_additional_fee_tax ? (
                      <Select
                        name="additional_fee_tax_id"
                        value={data.additional_fee_tax_id ? data.additional_fee_tax_id : ''}
                        options={taxGroupsOptions}
                        preInputStyle={{ width: 180 }}
                        style={$.extend({}, styles.rowItems, {
                          width: 180,
                          marginTop: 28,
                          paddingTop: 10,
                        })}
                        onChangeHandler={updateInventory}
                        MEDIA_URL={Pmp.Settings.MEDIA_URL}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
            </td>
            <td style={{ border: 0, verticalAlign: 'top' }}>
              <div style={styles.calculationColumn}>
                <p className="group-label">Total price to consumer</p>
                <table style={{ backgroundColor: '#EEE' }}>
                  <tbody>
                    {trElement(
                      'base',
                      priceFormatter.format(Number(data.price), {
                        code: currency_code,
                      })
                    )}
                    {data.apply_service_charge
                      ? trElement(
                          'service charge',
                          priceFormatter.format(_service_charge, {
                            code: currency_code,
                          })
                        )
                      : null}
                    {data.charge_tax ? trElement('tax', priceFormatter.format(_tax, { code: currency_code })) : null}
                    {data.charge_gratuity ? trElement('gratuity', priceFormatter.format(_grat, { code: currency_code })) : null}
                    {trElement(
                      'subtotal',
                      priceFormatter.format(Number(data.price) + _service_charge + _grat + _tax, {
                        code: currency_code,
                      }),
                      { borderTop: '1px dotted' }
                    )}
                    {data.charge_additional_fee
                      ? trElement(
                          (data.additional_fee_label || 'Admin fee').toLowerCase(),
                          priceFormatter.format(_additional_fee, {
                            code: currency_code,
                          })
                        )
                      : null}
                    {data.charge_additional_fee_tax
                      ? trElement(
                          `${data.additional_fee_label || 'Admin fee'} tax`.toLowerCase(),
                          priceFormatter.format(_additional_fee_tax, { code: currency_code })
                        )
                      : null}
                    {trElement('total', priceFormatter.format(total, { code: currency_code }), { fontWeight: 'bold', color: '#000' })}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  const quantityControl = (
    <div>
      <Input
        labelText="Total quantity available"
        name="total_quantity"
        value={data.total_quantity}
        required
        inputCss={{ width: 150 }}
        style={styles.rowItems}
        onChangeHandler={updateInventory}
        validator_type="strict_int_positive"
        validator_message="Enter tickets available"
      />
      <Input
        labelText="Min quantity per purchase"
        name="min_quantity"
        value={data.min_quantity}
        required
        inputCss={{ width: 150 }}
        style={styles.rowItems}
        onChangeHandler={updateInventory}
        tip="Require the user to purchase a minimum quantity of this inventory type."
        validator_type="strict_int_positive"
        validator_message="Enter minimum"
      />
      <Input
        labelText="Max quantity per purchase"
        name="max_quantity"
        value={data.max_quantity}
        required
        inputCss={{ width: 150 }}
        style={styles.rowItems}
        onChangeHandler={updateInventory}
        tip={`Set a limit on the maximum number of ${
          data.inventory_type === 'RESERVATION' ? 'reservations' : 'guestlist tickets'
        } that can be purchased by a single user.`}
        validator_type="int_positive"
        validator_message="Enter maximum"
      />

      {data.inventory_type === 'RESERVATION' ? (
        <Select
          labelText="Seating area"
          name="seating_area"
          defaultValue=""
          value={data.seating_area || ''}
          options={seatingAreasLookup}
          style={styles.rowItems}
          MEDIA_URL={Pmp.Settings.MEDIA_URL}
          tip="Reservations purchased will be assigned this seating area on the reservation book"
          onChangeHandler={updateInventory}
        />
      ) : null}

      {data.inventory_type === 'RESERVATION' && minPriceEnabled ? (
        <Input
          labelText="Minimum"
          name="min_price"
          value={data.min_price}
          required={false}
          inputCss={{ width: 150 }}
          style={styles.rowItems}
          onChangeHandler={updateInventory}
          tip="Reservations will be displayed with this minimum but the customer will only need to pay the Price at checkout"
          validator_type="positive_float_or_empty"
          validator_message="Enter minimum purchase"
        />
      ) : null}
    </div>
  )

  let inventory_note
  const inventory_title = data.id === 'new' ? `Add Inventory ${data.inventory_name}` : `Edit Inventory - ${data.inventory_name}`

  switch (data.inventory_type) {
    case 'RESERVATION':
      inventory_note = 'Reservations purchased will appear on your reservation list, with the purchase price in the prepayment field.'
      break
    case 'REQUEST':
      inventory_note = 'This inventory cannot be booked directly. All requests will appear in your Request Inbox.'
      break
    case 'GUESTLIST_TICKET':
      inventory_note = 'Guestlist tickets purchased will appear on your guestlist.'
      break
    case 'GUESTLIST_TICKET_FREE':
      inventory_note = 'These guestlist tickets allow for complimentary entry, and will appear on your guestlist once reserved.'
      break
    default:
      inventory_note = ''
      break
  }

  const getInventoryTiers = function () {
    const { inventory_name } = data
    const inventory_checkboxes = []
    for (let i = 0; i < eventData.inventories.length; i += 1) {
      if (eventData.inventories[i].inventory_name !== inventory_name) {
        inventory_checkboxes.push(
          <div key={`sr-inventory-checkbox-${eventData.inventories[i].inventory_name}`} style={{ paddingLeft: 67 }}>
            <CheckBox
              labelText={eventData.inventories[i].inventory_name}
              name={`tier-item-${eventData.inventories[i].id}`}
              checked={data.tier_list.indexOf(eventData.inventories[i].id) >= 0}
              onClickHandler={tieringCheckHandler}
            />
          </div>
        )
      }
    }
    return inventory_checkboxes
  }

  return (
    <div>
      <div id="shadow" style={shadow} onClick={loadInventory} />
      <div id="inventory-editor" style={styles.modal}>
        <Header title={inventory_title} />
        <div style={styles.content}>
          <div data-test="sr-icon-modal_close" onClick={loadInventory} style={styles.closer}>
            &times;
          </div>
          <form className="styled" data-sr-validate="always">
            <div>
              <Select
                labelText="Inventory type"
                name="inventory_type"
                value={data.inventory_type}
                options={typeOptions}
                onChangeHandler={updateInventory}
                MEDIA_URL={Pmp.Settings.MEDIA_URL}
              />
              <p className="note" style={styles.follow}>
                {inventory_note}
              </p>
            </div>
            <div style={styles.basicInfo}>
              <div style={{ float: 'left' }}>
                <Input
                  labelText="Inventory type name"
                  name="inventory_name"
                  value={data.inventory_name}
                  required
                  inputCss={{ width: 328 }}
                  style={styles.rowItems}
                  onChangeHandler={updateInventory}
                  validator_type="not_empty"
                  validator_message="Please name this inventory"
                />
                <p className="note" style={styles.eg}>
                  e.g. General Admission
                </p>
              </div>
              {data.inventory_type === 'GUESTLIST_TICKET_FREE' ? (
                <div style={{ float: 'left' }}>
                  <Input
                    labelText="Price"
                    name="comp_text"
                    value={data.comp_text}
                    inputCss={{ width: 150 }}
                    style={styles.rowItems}
                    disabled={can_edit_comp !== '1'}
                    onChangeHandler={updateInventory}
                    maxLength={12}
                  />
                  {can_edit_comp === '1' ? (
                    <p className="note" style={styles.eg}>
                      limit 12 characters
                    </p>
                  ) : null}
                </div>
              ) : null}
              {data.inventory_type === 'REQUEST' ? (
                <Input labelText="Price" name="price" value="Request" disabled inputCss={{ width: 150 }} style={styles.rowItems} />
              ) : null}
              {data.inventory_type === 'RESERVATION' || data.inventory_type === 'GUESTLIST_TICKET' ? (
                <Input
                  labelText={`Price (${priceFormatter.format(data.price, {
                    code: currency_code,
                  })})`}
                  name="price"
                  value={data.price}
                  required
                  inputCss={{ width: 150 }}
                  style={styles.rowItems}
                  onChangeHandler={priceUpdateHandler}
                  validator_type="positive_float"
                  validator_message="Please enter a positive price"
                />
              ) : null}
              {data.inventory_type === 'RESERVATION' ? (
                <Input
                  labelText={data.inventory_type === 'RESERVATION' ? 'Guest entry per reservation' : 'Guest entry per ticket'}
                  name="entry_per_reservation"
                  value={data.entry_per_reservation}
                  required
                  inputCss={{ width: 150 }}
                  style={styles.rowItems}
                  tip={`Purchase of 1 ${
                    data.inventory_type === 'RESERVATION' ? 'reservation' : 'guestlist ticket'
                  } will allow entry for up to this many guests.`}
                  onChangeHandler={updateInventory}
                  validator_type="strict_int_positive"
                  validator_message="Enter a number"
                />
              ) : null}
              <span style={{ clear: 'both', display: 'block' }} />
            </div>

            {data.inventory_type !== 'REQUEST' ? quantityControl : null}
            {data.inventory_type === 'RESERVATION' || data.inventory_type === 'GUESTLIST_TICKET' ? paymentControls : null}

            <div style={{ paddingBottom: 50 }}>
              <div style={styles.availTimes}>
                <RelativeTime
                  label="Sale starts"
                  name="sale_start"
                  data={data}
                  timeOptions={timeOptions}
                  updateInventory={updateInventory}
                />
                <div style={styles.calced}>
                  {`${sale_times_descriptive_text} begins ${
                    first_sale_start_time_moment < moment() ? 'immediately' : `from ${first_sale_start_time_moment.format('LLLL')}`
                  }`}
                </div>
                <RelativeTime label="Sale ends" name="sale_end" data={data} timeOptions={timeOptions} updateInventory={updateInventory} />
                <div style={styles.calced}>
                  {`${sale_times_descriptive_text} ends ${
                    first_sale_end_time_moment < moment() ? 'immediately' : `at ${first_sale_end_time_moment.format('LLLL')}`
                  }`}
                </div>
              </div>

              {data.inventory_type !== 'REQUEST' ? (
                <>
                  <div
                    style={{
                      ...styles.floatClear,
                      width: '723px',
                      padding: '15px 0 0 0',
                    }}
                  >
                    <p className="group-label">Booking Policy</p>
                    <Select
                      onChangeHandler={changeInventoryBookingPolicyType}
                      name="policy_type"
                      options={bookingPolicyOptions}
                      style={{ ...styles.floatClear }}
                      MEDIA_URL={Pmp.Settings.MEDIA_URL}
                      value={selectedBookingPolicy}
                      preInputStyle={{ width: 300 }}
                      inputCss={{ width: 300 }}
                    />
                    <TextArea
                      onChangeHandler={changeInventoryBookingPolicy}
                      disabled={!isCustomPolicy(selectedBookingPolicy)}
                      name="custom_booking_policy_textbox"
                      style={{ ...styles.floatClear, marginTop: 3 }}
                      inputCss={styles.customPolicies}
                      value={selectedBookingPolicyText}
                      required
                      validator_type="not_empty"
                      validator_message="Please enter text or select a policy"
                    />
                  </div>
                  <div
                    style={{
                      ...styles.floatClear,
                      width: '723px',
                      padding: '25px 0 5px 0',
                    }}
                  >
                    <p className="group-label">Cancellation Policy</p>
                    <Select
                      onChangeHandler={changeInventoryCancellationPolicyType}
                      name="cancellation_policy_type"
                      options={cancellationPolicyOptions}
                      style={{ ...styles.floatClear }}
                      MEDIA_URL={Pmp.Settings.MEDIA_URL}
                      value={selectedCancellationPolicy}
                      preInputStyle={{ width: 300 }}
                      inputCss={{ width: 300 }}
                    />
                    <TextArea
                      onChangeHandler={changeInventoryCancellationPolicy}
                      disabled={!isCustomPolicy(selectedCancellationPolicy)}
                      name="custom_cancellation_policy_textbox"
                      style={{ ...styles.floatClear, marginTop: 3 }}
                      inputCss={styles.customPolicies}
                      value={selectedCancellationPolicyText}
                      required
                      validator_type="not_empty"
                      validator_message="Please enter text or select a policy"
                    />
                  </div>
                  <div
                    style={{
                      ...styles.floatClear,
                      width: '723px',
                      padding: '25px 0 5px 0',
                    }}
                  >
                    <p className="group-label">Reservation Tags</p>
                    <ThemeProvider theme={Theme}>
                      <GenericTagsDropDown
                        useOutsideLabel={false}
                        tagGroups={tagGroups}
                        venueId={venueId}
                        selectedTags={data.selected_tags}
                        onChangeHandler={changeSelectedTags}
                        height="44px"
                        width="100%"
                      />
                    </ThemeProvider>
                  </div>
                </>
              ) : null}
              <div style={{ clear: 'both', paddingTop: 20 }}>
                <p className="group-label">Show inventory</p>
                <Switch
                  name="hide_inventory"
                  toggle={toggleHidden}
                  testId="sr-switch-show-inventory"
                  value={data.hide_inventory}
                  style={{ float: 'left' }}
                />
                <span style={switchLabel}>Show inventory on events page</span>
              </div>
              {eventData.inventories.length > 1 && (
                <div style={{ clear: 'both', paddingTop: 20 }}>
                  <p className="group-label">Tier inventory</p>
                  <Switch
                    name="hide_tiering"
                    testId="sr-switch-hide_tiering"
                    toggle={onToggleTiering}
                    value={data.hide_tiering}
                    style={{ float: 'left' }}
                  />
                  <span style={switchLabel}>Do not show until all selected inventory is sold out/sale end</span>
                  {!data.hide_tiering && getInventoryTiers()}
                </div>
              )}
              <div style={{ clear: 'both', paddingTop: 15 }}>
                <p className="group-label">Guest Checkout</p>
                <Switch
                  name="guest_checkout"
                  toggle={toggleGuestCheckout}
                  testId="sr-switch-guest_checkout"
                  value={data.guest_checkout}
                  style={{ float: 'left' }}
                />
                <span style={switchLabel}>Allows guest to checkout without social login</span>
              </div>
              {data.inventory_type === 'RESERVATION' || data.inventory_type === 'GUESTLIST_TICKET' ? (
                <div style={{ clear: 'both', paddingTop: 15 }}>
                  <p className="group-label">Promo code</p>
                  <Switch
                    name="enable_promocodes"
                    toggle={toggleEnablePromocodes}
                    testId="sr-switch-promo_code"
                    value={data.enable_promocodes}
                    style={{ float: 'left' }}
                  />
                  <span style={switchLabel}>Allows guests to try promo codes on this inventory</span>
                </div>
              ) : null}
              {featureFlags.useAdminPanelFloorplanImages && svgClasses.length > 0 && (
                <div style={{ clear: 'both', paddingTop: 15 }}>
                  <p className="group-label" style={{ fontSize: 18 }}>
                    3D Floorplan Inventory Details
                  </p>
                  <Select
                    labelText="3D floorplan area"
                    name="floorplan_inventory_classname"
                    value={data.floorplan_inventory_classname}
                    options={svgClasses.reduce(
                      (acc, className) => ({
                        ...acc,
                        [className]: className.replace(/-+/g, ' ').replace(/\b\w/g, char => char.toUpperCase()),
                      }),
                      { null: '--select--' }
                    )}
                    preInputStyle={{ minWidth: 300, width: 'auto' }}
                    inputCss={{ minWidth: 300, padding: '6px 20px 6px 6px', width: 'auto' }}
                    onChangeHandler={updateInventory}
                    MEDIA_URL={Pmp.Settings.MEDIA_URL}
                  />
                </div>
              )}
            </div>
          </form>
          <ControlBar cancelAction={loadInventory} saveAction={saveInventory} addStyles={styles.modalPosition} />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  venueInteractiveFloorplanImages: state.venueConfig.venue_interactive_floorplan_images,
  featureFlags: state.featureFlags,
})
export default connect(mapStateToProps)(EditInventory)
