import { brandColor } from './general'

export const newInventory = {
  background: '#fff',
  border: `1px solid ${brandColor}`,
  color: brandColor,
  padding: '0 20px',
  width: 120,
}

export const buttonAdd = {
  background: brandColor,
  borderRadius: 10,
  color: '#fff',
  display: 'inline-block',
  fontSize: 14,
  height: 14,
  lineHeight: '14px',
  marginRight: 10,
  textAlign: 'center',
  width: 14,
}

export const row = {
  border: '1px solid #dadada',
  borderRadius: '3px',
  clear: 'both',
  float: 'left',
  marginBottom: 10,
  padding: 10,
  width: 800,
}

export const hiddenText = {
  color: '#999',
  fontSize: 18,
}

export const cell = {
  float: 'left',
}

export const title = {
  width: 300,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  marginRight: 20,
}

export const price = {
  width: 160,
}

export const availability = {
  width: 100,
}

export const actions = {
  float: 'right',
  textAlign: 'right',
  width: 200,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-end',
}

export const info = {
  color: '#878787',
  fontFamily: 'Roboto',
  fontSize: 17,
  fontWeight: 'normal',
  height: 21,
  lineHeight: '21px',
  marginBottom: 4,
}

export const note = {
  color: '#A7A7A7',
  height: 'auto',
  fontSize: 12,
  fontStyle: 'italic',
  lineHeight: '15px',
}
